import {computed} from "vue";
import {RouteLocationNormalized} from "vue-router";
import {RouteNameEnum} from "@/v2/enumerate/route";

/**
 * 判斷頁面可使用的能力
 */
export const useMaintenance = () => {

    /**
     * 牧養相關頁面
     */
    const shepherdPage: string[] = [
        RouteNameEnum.SHEPHERD_ATTEND,
        RouteNameEnum.SHEPHERD_ATTEND_CREATE,
        RouteNameEnum.SHEPHERD_ATTEND_EDIT,
        RouteNameEnum.SHEPHERD_ATTEND_EDIT_SCHEDULE,
        RouteNameEnum.SHEPHERD_ATTEND_EDIT_SCHEDULE_EDIT,
        RouteNameEnum.SHEPHERD_ATTEND_EDIT_HISTORY,
        RouteNameEnum.SHEPHERD_ATTEND_EDIT_HISTORY_EDIT,
        RouteNameEnum.SHEPHERD_ATTEND_CATEGORY,
        RouteNameEnum.SHEPHERD_ATTEND_CATEGORY_CREATE,
        RouteNameEnum.SHEPHERD_ATTEND_CATEGORY_EDIT,
        "ShepherdTeam",
        "ShepherdTeamAttend",
        "ShepherdTeamStatistics",
        "ShepherdTeamStatisticsAttend",
        "ShepherdTeamStatisticsEvent",
        "ShepherdTeamStatisticsGender",
        "ShepherdTeamStatisticsYear",
        "ShepherdTeamStatisticsDeacon",
        "ShepherdTeamStatisticsEducation",
        "ShepherdTeamStatisticsOccupation",
        "ShepherdTeamStatisticsMarital",
        "ShepherdTeamStatisticsBaptized"
    ]

    /**
     * 牧養團隊志願教會
     */
    const shepherdVolunteer: string[] = [
        "0000",
        "0001",
        "0272",
        "0282",
        "0410",
        "testChurch"
    ].map((s) => s.toLowerCase())

    /**
     * 牧養系統
     * false=可通過, true=不可以通過
     */
    const isShepherd = computed((): (route: RouteLocationNormalized) => boolean => (e) =>{
        // 非牧養相關頁面, 返回 false
        if (!shepherdPage.includes(e.name as string)) return false
        // 牧養團隊志願教會, 返回 false
        else if (shepherdVolunteer.includes((e.params.id as string).toLowerCase())) return false
        // 剩下看.env 是否有限制
        return (import.meta.env.VITE_IS_MAINTENANCE_SHEPHERD as string).toLowerCase() === "true"
    })

    return {
        isShepherd
    }

}
